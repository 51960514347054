import service from "@/utils/index"
// 获取运营商
export const getSys = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_vender_list",
        data:data
    })
}
// 获取小区列表
export const getSysvill = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_district_list",
        data:data
    })
}
// 获取通知列表
export const getSystz = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_notice_list",
        data:data
    })
}
// 获取通知详情
export const getSystzs = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_notice_info",
        data:data
    })
}
// 修改密码
export const getSysmima = (data) => {
    return service({
        method: "post",
        url: "/Sys/password_edit",
        data:data
    })
}
// 查找运营商手机号
export const getSysphone = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_vender_phone",
        data:data
    })
}
// 手机号解绑/绑定
export const getSysphxg = (data) => {
    return service({
        method: "post",
        url: "/Sys/phone_edit",
        data:data
    })
}
// 首页商创
export const getSc = (data) => {
    return service({
        method: "post",
        url: "/Insure/Insure",
        data:data
    })
}
export const getScs = (data) => {
    return service({
        method: "post",
        url: "/Insure/insure_single_day_list",
        data:data
    })
}
export const getSct = (data) => {
    return service({
        method: "post",
        url: "/Income/income_echart_data",
        data:data
    })
}
// 发送验证码
export const getSysyzm = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_sms_code",
        data:data
    })
}
// 获取下级商户
export const getSysxia = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_relation_vender",
        data:data
    })
}
// 退出登录
export const getSysT = (data) => {
    return service({
        method: "post",
        url: "/Sys/login_out",
        data:data
    })
}

export const getT = (data) => {
    return service({
        method: "post",
        url: "/Login/token_error",
        data:data
    })
}

export const getSysy = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_area_list",
        data:data
    })
}

export const getSysdy = (data) => {
    return service({
        method: "post",
        url: "/sys/check_vender_subscribe",
        data:data
    })
}