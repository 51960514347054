<template>
  <div class='index'>
    <van-tabs v-model="active" @click="rel(active)">
      <van-tab title="驾驶舱" name="0">
        <Index1 :key="arr[0].key"  ref="index1"/>
      </van-tab>
      <van-tab title="营收统计" name="1">
        <Index2 :key="arr[1].key" />
      </van-tab>
      <van-tab title="数据图表" name="2">
        <Index3 :key="arr[2].key" />
      </van-tab>
    </van-tabs>
    <!-- <img src="../../assets/index/a.png" style="width: 100%;" :show-menu-by-longpress="true">  长按识别二维码-->
    <div class="index_mb fl1" v-if="a == 1" @click.self="a = 2">
      <div style="width: 80%;">
        <div style="position: relative;">
          <img src="../../assets/index/a.png" style="width: 100%;">
          <button class="mb_btn" @click="dy()">立即订阅</button>
        </div>
        <div style="text-align: center;"><img src="../../assets/c.png" style="width: 4rem;margin-top: 2rem;" @click="a = 2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Index1 from "./index11.vue"
import Index2 from "./index2.vue"
import Index3 from "./index3.vue"
import { getInlet } from "@/api"
import { getSysdy } from "@/api/sys"
import bus from "../../store/bus"
export default {
  components: { Index1, Index2, Index3 },
  data() {
    return {
      active: '0',
      a:'',
      worktable: '',
      alert: 0,
      tab: false,
      arr: [{ key: '0' }, { key: '1' }, { key: '2' }]
    };
  },
  methods: {
    rel(name) {
      this.arr[name].key = new Date().getTime();
    },
    async inlet() {
      // 获取入口
      let data = await getInlet();
      this.worktable = data.data.data.check_worktable;
      this.alert = data.data.data.check_alert;
      bus.$emit("worktable", this.worktable);
    },
    async inDy() {
      let data = await getSysdy();
      this.a = data.data.data.subscribe_notice;
    },
    goIndex(){
      this.$refs.index1.golist();
    },
    dy(){
      window.location.href = 'https://api.xkmip.cn/Mbusiness/login/vender_bind?token=' + localStorage.getItem('token');
    },
    gol(){
      this.dis = false;
      this.$router.push({ path: "/info" });
      window.scrollTo(0, 0);
    },
    // //弹窗一天一显示
    // showMo() {
    //     const modalKey = 'modalShownToday';
    //     const today = new Date();
    //     today.setHours(0, 0, 0, 0);
    //     const lastShown = new Date(localStorage.getItem(modalKey) || '');
    //     if (!lastShown || lastShown.getTime() !== today.getTime()) {
    //       this.dis = true;
    //       localStorage.setItem(modalKey, today.toISOString());
    //     }
    // }
  },
  created(){
    // this.showMo();
    this.inDy();
  },
  mounted() {
    this.inlet();
    if (this.$route.query.int == 'false') {
      window.location.href = 'https://vender.xingkeele.com/#/index';
    };
  },
  beforeRouteEnter(to, from, next) {
    if (from.path == '/more' || from.path == '/morelist') {
      next(vm => {
         vm.goIndex();
        });
    } else {
        next();
    }
  }
}
</script>

<style lang="less">
.index_mb{
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  .mb_btn{
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #409eff;
    color: #fff;
    padding: 1.5rem 3rem;
    border-radius: .6rem;
    font-size: 1.7rem;
  }
}
.index1 {
  height: 100vh;
  background-color: #f2f3f5;

  .h1 {
    height: 1rem;
  }

  .p1 {
    padding: 0 1rem;
  }

  .fsc {
    font-size: 1.2rem;
    color: #e9e9e9;
  }

  .tt_top {
    width: 2.4rem;
    height: 4rem;

    img {
      width: 1.7rem;
      margin-top: 1rem;
    }
  }

  .fsc1 {
    font-size: 1.3rem;
    color: #e9e9e9;
  }

  .mt_5 {
    margin-top: 0.5rem;
  }

  .fl {
    display: flex;
    align-items: flex-end;
  }

  .seamless {
    height: 3rem;
    line-height: 3rem;
  }

  .blue {
    background: url(../../assets/index/bg.png) 0 0 no-repeat;
    background-size: 100% 100%;
    margin-top: .4rem;
    padding: 1.5rem 1.5rem 1rem !important;
  }

  .index_head_top {
    background: url(../../assets/index/bgs.png) 0 0 no-repeat;
    background-size: 100% 100%;
    padding: 1.5rem 1.5rem 1rem;

    .head_top_t {
      height: 1.8rem;

      .top_t_l {
        color: #fff;
      }

      .top_t_r {
        
        color: #fff;
      }
    }

    .head_top_b {
      color: #fff;

      .top_b_l {
        margin-top: .8rem;
        font-size: 2.2rem;
      }

      .top_b_r {
        
        text-align: right;

        .top_b_r_text {
          width: 7rem;
        }
      }
    }
  }

  .head_bom {
    width: 45%;
    line-height: 1.7;

    .head_bom_t {
      .bom_t_l {
        color: #fff;
      }

      .bom_t_r {
        img {
          width: 2.4rem;
        }
      }
    }

    .head_bom_b {
      
      color: #fff;
      align-items: first baseline!important;
    }
  }

  .index_tt {
    background-color: #fff;
    border-radius: .4rem;
    margin: .6rem 0;
    height: 4rem;
    color: #a5a7b1;

    .tt_box {
      display: flex;
      align-items: center;
      margin: 0 1rem;
    }

    .tt_text {
      margin-left: .4rem;
      overflow: hidden;
      width: 78%;
    }
  }

  .index_sj {
    background: #fff;
    box-shadow: 0rem .1rem .4rem .1rem rgba(0, 0, 0, 0.05);
    border-radius: .6rem;
    padding: 1.6rem;
    margin: .6rem 0;

    .index_sj_box {
      width: 50%;
      display: flex;

      .box_img {
        width: 3rem;
        height: 3rem;
      }

      .sj_box_img {
        margin: 0 1rem;
      }

      .text1 {
        margin-bottom: .4rem;
        color: #a5a7b1;
      }

      .sj_box_text {
        margin-top: .4rem;
      }

      .text2 {
        font-size: 1.5rem;
        font-weight: 600;
        color: #444;
      }
    }
  }

  .index_tu {
    height: 16rem;
    background: #fff;
    box-shadow: 0rem .1rem .2rem 0rem rgba(0, 0, 0, 0.06),
      0rem .1rem .2rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: .6rem;
    
  }

  .ib {
    display: inline-block;
  }

  .jcsb {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .fs20 {
    font-size: 2rem;
  }

  .mr6 {
    margin-right: .6rem;
  }

  .fs18 {
    font-size: 1.8rem;
  }

  .fs15 {
    font-size: 1.6rem;
  }

  .mg {
    margin: 1.3rem 0;
  }

  canvas {
    width: 98% !important;
  }
}
</style>