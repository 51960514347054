<template>
    <div class='scroll'>
        <img src="../assets/zd.png" class="scroll_box" @click="zd()">
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        zd() {
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="less">
.scroll {
    .scroll_box {
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #f2f3f5;
        position: fixed;
        bottom: 6rem;
        right: 1.2rem;
    }
}
</style>