import service from "@/utils/index"
// 获取运营首页
export const getOind = (data) => {
    return service({
        method: "post",
        url: "/Income/index",
        data:data
    })
}
// 获取运营月收入
export const getOyear = (data) => {
    return service({
        method: "post",
        url: "/Income/money_month_list",
        data:data
    })
}
// 获取运营小区收入
export const getOvill = (data) => {
    return service({
        method: "post",
        url: "/Income/money_district_list",
        data:data
    })
}
// 获取运营日收入
export const getOday = (data) => {
    return service({
        method: "post",
        url: "/Income/money_day_list",
        data:data
    })
}
// 获取运营日消费
export const getOs = (data) => {
    return service({
        method: "post",
        url: "/Income/orders_day_list",
        data:data
    })
}
// 获取运营日消费
export const getYs = (data) => {
    return service({
        method: "post",
        url: "/Income/orders_month_list",
        data:data
    })
}