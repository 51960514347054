<template>
    <div class='my'>
        <div class="h1"></div>
        <div class="con">
            <div class="con_text" @click="mima()">
                <div>修改密码</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="line"></div>
            <div class="con_text" @click="phones()">
                <div>绑定手机</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="line"></div>
            <div class="con_text" @click="xia()" v-if="oul.length != 0">
                <div>下级商户</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="line" v-if="oul.length != 0"></div>
            <div class="con_text" @click="acc()">
                <div>账号切换</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="line"></div>
            <div class="con_text" @click="dy()" v-if="a">
                <div>订阅消息</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="line" v-if="a"></div>
            <!-- <div class="line"></div>
            <div class="con_text" @click="xufei()">
                <div>续费管理</div>
                <img src="../../assets/myy.png" />
            </div> -->
            <div class="con_text" @click="hows = true">
                <div>退出登录</div>
                <img src="../../assets/myy.png" />
            </div>
            <div class="user_bgs" v-if="hows" @click.self="hows = false">
                <div class="bgs">
                    <div class="fl1" style="justify-content: space-between;">
                        <div></div>
                        <div></div>
                        <img src="../../assets/cha.png" style="width: 1.2rem;" @click="hows = false">
                    </div>
                    <div class="h1"></div>
                    <div style="text-align: center;" class="fs16">是否退出当前账户?</div>
                    <div class="h1"></div>
                    <div class="bgs_s">
                        <div class="btn btn1" @click="hows = false">取 消</div>
                        <div class="btn" @click="qr1()">确 认</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getSysxia, getSysT } from '@/api/sys'
export default {
    components: {},
    data() {
        return {
            oul: [],
            a:false,
            hows: false,
        }
    },
    methods: {
        // 退出提交
        async qr1() {
            let data = await getSysT();
            if (data.data.code == 1) {
                window.location.href = data.data.data;
                localStorage.clear();
            }
        },
        // 修改密码
        mima() {
            this.$router.push({
                path: '/setm'
            })
        },
        // 绑定手机
        phones() {
            this.$router.push({
                path: '/phones'
            })
        },
        // 下级商户
        async xia() {
            if (this.oul.length != 0) {
                this.$router.push({
                    path: '/below', query: { 'oul': this.oul }
                })
            }
        },
        acc() {
            this.$router.push({
                path: '/acc'
            })
        },
        // 续费管理
        // xufei() {
        //     this.$router.push({
        //         path: '/renew'
        //     })
        // },
        dy() {
            window.location.href = 'https://api.xkmip.cn/Mbusiness/login/vender_bind?token=' + localStorage.getItem('token');
        }
    },
    async mounted() {
        if (localStorage.getItem("type") == 2) {
            this.a = true;
        }else{
            this.a = false;
        }
        let data = await getSysxia({ token: localStorage.getItem('token') });
        if (data.data.code == 1) {
            this.oul = data.data.data;
        }
    }
}
</script>

<style lang="less">
.my {
    .con {
        margin: 0 1rem;
        padding: .6rem 0;
        font-size: 1.5rem;
        border-radius: .6rem;
        background-color: #fff;

        .con_text {
            display: flex;
            align-items: center;
            padding: .6rem 2rem;
            justify-content: space-between;
        }
    }

    .fs16 {
        font-size: 1.5rem;
        font-weight: 600;
    }

    .h1 {
        height: 1rem;
    }

    .user_bgs {
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.5);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        z-index: 999;

        .bgs {
            background-color: #fff;
            width: 70%;
            padding: 2rem;
            border-radius: 1rem;
            margin: 0 auto;

            .van-dropdown-menu__bar {
                box-shadow: none;
                width: 80%;
                border-radius: .6rem;
                border: 1px solid #A6A6A6;
            }

            .van-cell__value {
                display: none;
            }

            .van-cell__title {
                text-align: center;
            }

            .bgs_s {
                display: flex;
                justify-content: space-around;
                align-items: center;
                margin-top: 3rem;

                .btn {
                    background-color: #3091FF;
                    border-radius: .4rem;
                    text-align: center;
                    width: 40%;
                    color: #fff;
                    padding: .8rem;
                }

                .btn1 {
                    background-color: rgba(0, 0, 0, 0.05);
                    color: #000;
                }
            }
        }
    }

    .line {
        width: 90%;
        height: .1rem;
        margin: 0 auto;
        background-color: #F6F7F0;
    }
}
</style>