import Vue from 'vue'
import App from './App.vue'
import router from "./router/index"
Vue.config.productionTip = false
import store from './store'
import axios from 'axios'
Vue.prototype.axios = axios

import Vant from 'vant'
import 'vant/lib/index.css'
Vue.use(Vant)
import { Toast } from 'vant'
Vue.use(Toast)

import moment from "moment"
Vue.prototype.$moment = moment

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(echarts)

Vue.prototype.$redirectToLogin1 = function () {
  app.$router.push({ path: '/login?id=-8' })
}

import MintUI from 'mint-ui'
import 'mint-ui/lib/style.css'
Vue.use(MintUI)

import wx from 'weixin-js-sdk'
Vue.prototype.wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']

const app = new Vue({
  router,
  store: store,
  render: h => h(App),
}).$mount('#app')