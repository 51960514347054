<template>
  <div id="app">
    <keep-alive><router-view v-if="$route.meta.keeps"></router-view></keep-alive>
    <router-view v-if="!$route.meta.keeps"></router-view>
    <Tab v-show="this.$route.meta.hidden"></Tab>
  </div>
</template>

<script>
import Tab from "./components/tab.vue"
import style from "./assets/base.css"
export default {
  name: 'App',
  data() {
    return {};
  },
  components: {
    Tab
  }
}
</script>

<style lang="less">
.van-tab {
  font-size: 1.6rem !important;
}

.van-tabs--line .van-tabs__wrap {
  height: 5rem !important;
}

.van-popover--dark {
  left: 6.6rem !important;
  font-size: 1.3rem !important;
}

.van-search__content {
  width: 100%;
}

.van-popover__action {
  height: 6rem;
  padding: 0 .6rem;
}

.mint-msgbox-content {
  border-bottom: none !important;
}

.mint-msgbox-cancel {
  border-right: none !important;
}

.mint-msgbox-title {
  font-weight: 400 !important;
}

.mint-msgbox {
  border-radius: 2rem !important;
}

.van-field__clear {
  display: none;
}

.xq_ven{
      background-color: #F7F7F7;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      border-radius: .6rem;
      text-align: center;
}

#app {
  position: relative;
  // .log {
  //   width: 100%;
  //   height: 100vh;
  //   position: relative;
  //   background: url(./assets/lo.jpg) 0 0 no-repeat;
  //   background-size: 100% 100%;
  //   z-index: 999;

  //   .log_box {
  //     width: 3.6rem;
  //     height: 3.6rem;
  //     top: 1rem;
  //     right: 1rem;
  //     position: absolute;
  //     color: #fff;
  //     justify-content: center;
  //     border-radius: 50%;
  //     background-color: rgba(0, 0, 0, 0.5);
  //   }
  // }

  .van-tab--active {
    color: #3091FF;
  }

  .tk {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 110;

    .tk_t {
      width: 85%;
      height: 76%;

      img {
        width: 100%;
      }
    }

    .del {
      background-color: #3a8eed;
      color: #fff;
      font-size: 1.5rem;
      position: absolute;
      bottom: 3rem;
      height: 4rem;
      width: 50%;
      border-radius: 3rem;
      justify-content: center;
    }
  }

  .btg {
    border-radius: 0% !important;
  }

  .qxz {
    background: url(./assets/sb/qxz.png) 0 0 no-repeat !important;
    background-size: 100% 100% !important;
    border: none !important;
  }
  .van-calendar__body{
    .van-calendar__bottom-info{
      display: none;
    }
    .van-calendar__day--start,.van-calendar__day--end{
      color: #fff !important;
    }
    .van-calendar__month-mark{
      // display: none !important;
      color: #ccc;
    }
    .van-calendar__day{
      color: #000;
    }
    .van-calendar__day--disabled{
      color: #ccc;
    }
  }
  .lines {
    width: 100%;
    height: .1rem;
    background-color: #eee;
    margin: .8rem 0 !important;
  }

  .loads{
    justify-content: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    .xql {
      padding: 2.6rem 3rem;
      border-radius: 1rem;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  
  .wn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .xq_fl {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cha_xq_con {
    box-sizing: border-box;
  }
  .van-popup--top{
    &::-webkit-scrollbar {
        display: none
    }
  }

  .tab {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: 100;
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .iconfont {
      font-size: 2.4rem;
    }

    #tab_box {
      width: 5rem;
      text-align: center;

      img {
        width: 3rem;
        height: 3rem;
      }
    }

    .tab_box_img {
      height: 2.2rem;
    }

    .tab_box_img img {
      width: 2.2rem;
      height: 100%;
    }

    .blue {
      color: #3091ff;
    }
  }

  .w50 {
    width: 50%;
  }

  .fl1 {
    display: flex;
    align-items: center;
  }

  .line {
    width: 100%;
    height: .1rem;
    background-color: #eee;
    margin: 1rem 0;
  }

  .van-field__control:disabled {
    color: #666;
    -webkit-text-fill-color: #666;
  }

  .van-dropdown-item--down {
    transition: animation-duration 0.3s ease;
  }

  .van-dropdown-item {
    z-index: 999;
  }

  .dropdown {
    .dropdown-input {
      padding: 0 !important;
      height: 3.6rem;
      border-radius: .5rem;
      text-align: center;
      background-color: #F7F7F7;
      border: none;
      width: 100%;
      font-size: 1.5rem;
      max-width: 100%;
      min-width: 100%;
    }

    .dropdown-content {
      max-height: 10rem !important;
      min-width: 100%;
      border: none !important;
      box-shadow: none;
      max-width: 100%;

      .dropdown-item {
        line-height: 1.4;
        font-size: 1.5rem;
        padding: .6rem 0 !important;
        text-align: center;
      }
    }
  }

  .gjc {
    position: absolute;
    right: 1rem;
    width: 3.6rem;
    z-index: 99;
  }

  .van-search__content--round {
    border-radius: .6rem;
    background-color: #fff;
  }

  .van-dropdown-menu__title::after {
    right: -.5rem !important;
  }

  .van-tabs__line {
    width: 5rem;
    background-color: #3091FF;
    border-radius: 0px !important;
    bottom: .6rem;
  }

  .van-tabs__nav--line {
    padding: 0 !important;
  }

  .van-tab,
  .van-tabs__nav {
    background-color: #f4f4f4;
  }

  .van-dropdown-item__content {
    max-height: 50% !important;
  }

  .van-button--default {
    border: none;
  }

  .van-dropdown-menu__title,
  .van-ellipsis {
    font-size: 1.5rem;
    color: #666;
  }

  .mt-progress {
    line-height: normal;
    height: .6rem;

    .mt-progress-runway,
    .mt-progress-progress {
      border-radius: .6rem;
    }
  }

  .tb_img {
    img {
      width: 1.6rem;
    }
  }

  .van-search {
    padding: 0;
  }

  .van-button--round {
    border-radius: .6rem;
    margin-bottom: 1.5rem;
  }

  .van-icon-search:before {
    content: ''
  }

  .van-search__action {
    display: none;
  }

  .header {
    width: 100%;
    height: 4.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;

    .header_img {
      width: 9px;
      height: 17px;
    }

    .header_text {
      font-size: 1.8rem;
      font-weight: 400;
      color: #232832;
    }
  }

  .payTop::-webkit-scrollbar {
    display: none
  }

  .flc {
    justify-content: center;
    font-size: 1.7rem;
  }

  .van-icon-success:before {
    content: '';
  }
}
</style>
