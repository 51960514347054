<template >
  <div class="tab">
    <router-link :to="worktable == 1 ? '/index' : '/menu'" tag="div" id="tab_box"
      :class="$route.path == '/index' ? 'blue' : ''">
      <i :class="['iconfont', $route.path == '/index' ? 'icon-31shouyexuanzhong' : 'icon-31shouye']"></i>
    </router-link>
    <router-link to="/menu" tag="div" id="tab_box" :class="$route.path == '/menu' ? 'blue' : ''">
      <i :class="['iconfont', $route.path == '/menu' ? 'icon-caidan-' : 'icon-caidan']"></i>
    </router-link>
    <router-link to="/my" tag="div" id="tab_box" :class="$route.path == '/my' ? 'blue' : ''" v-if="$route.meta.flag">
      <i :class="['iconfont', $route.path == '/my' ? 'icon-wode1' : 'icon-wode']"></i>
    </router-link>
    <router-link to="" tag="div" id="tab_box" v-else>
      <div @click="go()">
        <img src="../assets/fhui.png">
      </div>
    </router-link>
  </div>
</template>

<script>
import bus from "../store/bus"
import icon from "@/assets/fonts/iconfont.css"
export default {
  components: {},
  data() {
    return {
      worktable: 1,
    };
  },
  methods: {
    go() {
      this.$router.back();
    }
  },
  created() {
    bus.$on("worktable", (data) => {
      this.worktable = data;
    });
  }
};
</script>
<style lang="less"></style>