import axios from "axios"
import { Toast } from "vant"
import Vue from "vue"
let service = axios.create({
    baseURL: '/Mbusiness',
    timeout: 80000,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
});
// 请求拦截器
service.interceptors.request.use(function (config) {
    config.headers['token'] = `${localStorage.getItem('token')}`;
    return config;
}, function (error) {
    return Promise.reject(error);
});
// 响应拦截器
service.interceptors.response.use(function (response) {
    if (response.data.code == -1) {
        Toast(response.data.msg);
        return response;
    } else if (response.data.code == -9) {
        Vue.prototype.$redirectToLogin1.call(this);
    } else if (response.data.code == -8) {
        Vue.prototype.$redirectToLogin1.call(this);
    } else {
        return response;
    }
}, function (error) {
    return Promise.reject(error);
});
export default service