<template>
    <div class='morelist' ref="more">
        <div class="more_list" ref="more1">
            <p class="list_p1">{{ daTa.notice_title }}</p>
            <p class="list_p2">{{ daTa.ctime }}</p>
            <div v-html="daTa.notice_content" id="more_html"></div>
        </div>
    </div>
</template>

<script>
import { getSystzs } from "@/api/sys"
export default {
    components: {},
    data() {
        return {
            daTa: '',
            id: '',
        };
    },
    methods: {
        async get() {
            let data = await getSystzs({ token: localStorage.getItem('token'), notice_id: this.id });
            if (data.data.code == 1) {
                this.daTa = data.data.data;
            }
        },
        hasBar(element) {  
           let elementHeight = element.offsetHeight;  
           let viewportHeight = window.innerHeight;   
           return elementHeight > viewportHeight ;  
        }  
    },
    created() {
        this.id = this.$route.query.id;
        this.get();
    },
    updated(){
        if (this.hasBar(this.$refs.more1)) {  
            this.$refs.more.style.height = 'auto';
            var newChild = document.createElement('div');
            newChild.style.height = '3rem';
            this.$refs.more.appendChild(newChild);  
        } else {  
            this.$refs.more.style.height = 'calc(100vh - 4rem)';
        }
    }
}
</script>
<style lang='less'>
.morelist {
    padding: 2rem 0;
    background-color: #fff;
    font-size: 1.6rem;

    #more_html{
        font-size: 1.5rem;
        p{
            img{
                width: 100% !important;
            }
        }
    }

    .more_list {
        margin: 0 auto;
        width: 90%;
    }

    .list_p1 {
        text-align: center;
        padding: 0 1rem;
    }

    .list_p2 {
        text-align: right;
        color: #999;
        margin: 1rem 0;
        padding: 0 1rem;
        
    }
}
</style>