import service from "@/utils/index"
// 获取token
export const getToken = (data) => {
    return service({
        method: "post",
        url: "/Login/get_token",
        data
    })
}
// 入口配置
export const getInlet = (data) => {
    return service({
        method: "post",
        url: "/Sys/get_sys_conf",
        data
    })
}
// 首页数据
export const getIndex = (data) => {
    return service({
        method: "post",
        url: "/Index/workspace_n",
        data
    })
}
export const getInto = (data) => {
    return service({
        method: "post",
        url: "/income/withdraw_list",
        data
    })
}
export const getInt = (data) => {
    return service({
        method: "post",
        url: "/income/profit_list",
        data
    })
}
export const getInts = (data) => {
    return service({
        method: "post",
        url: "/income/district_profit",
        data
    })
}
export const getInmx = (data) => {
    return service({
        method: "post",
        url: "/income/district_profit_list",
        data
    })
}
export const getInx = (data) => {
    return service({
        method: "post",
        url: "/income/withdraw_add",
        data
    })
}
export const getInxs = (data) => {
    return service({
        method: "post",
        url: "/income/withdraw_online_add",
        data
    })
}
export const getInp = (data) => {
    return service({
        method: "post",
        url: "/income/profit_withdraw_auto",
        data
    })
}
export const getLo = (data) => {
    return service({
        method: "post",
        url: "/Sys/vender_list",
        data
    })
}
export const getMs = (data) => {
    return service({
        method: "post",
        url: "/tool/tool_user_list",
        data
    })
}
export const getMdel = (data) => {
    return service({
        method: "post",
        url: "/tool/tool_user_del",
        data
    })
}
export const getMss = (data) => {
    return service({
        method: "post",
        url: "/tool/tool_user_audit",
        data
    })
}
export const getXp = (data) => {
    return service({
        method: "post",
        url: "/district/district_money_order",
        data
    })
}