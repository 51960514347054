import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import MoreList from "@/views/index/morelist.vue"
import Index from "@/views/index/index.vue"
import Login from "@/views/login/login.vue"
import Loinx from "@/views/login/loinx.vue"
import More from "@/views/index/more.vue"
import Menu from "@/views/menu/menu.vue"
import My from "@/views/my/my.vue"

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};
let routes = [{
    path: '/',
    redirect: "/login",
},{
    path: '/login',
    component: Login,
},{
    path: '/loinx',
    component: Loinx,
    meta: {
        keeps: true,
        hidden: false,
        flag: true
    }
},{
    path: '/index',
    component: Index,
    meta: {
        title: "首页",
        keeps: true,
        hidden: true,
        flag: true
    }
},{
    path: '/more',
    component: More,
    meta: {
        title: "通知",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/morelist',
    component: MoreList,
    meta: {
        title: "通知详情",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/menu',
    component: Menu,
    meta: {
        title: "菜单中心",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/info',
    component: () => import('@/views/info/info.vue'),
    meta: {
        title: "商户进件",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/my',
    component: My,
    meta: {
        title: "个人中心",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/setm',
    component: () => import('@/views/my/set.vue'),
    meta: {
        hidden: true,
        flag: false,
        keeps: false,
    }
},{
    path: '/dy',
    component: () => import('@/views/my/dy.vue'),
    meta: {
        hidden: true,
        flag: false,
        keeps: false,
    }
},{
    path: '/acc',
    component: () => import('@/views/my/acc.vue'),
    meta: {
        hidden: true,
        flag: false,
        keeps: false,
    }
},{
    path: '/phones',
    component: () => import('@/views/my/phones.vue'),
    meta: {
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/renew',
    component: () => import('@/views/my/renew.vue'),
    meta: {
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/below',
    component: () => import('@/views/my/below.vue'),
    meta: {
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/village',
    component: () => import('@/views/villages/village.vue'),
    meta: {
        title: "小区首页",
        hidden: true,
        keeps: true,
        flag: false
    }
},{
    path: '/villxz',
    component: () => import('@/views/villages/villxz.vue'),
    meta: {
        title: "小区新增",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/admin',
    component: () => import('@/views/villages/admin.vue'),
    meta: {
        title: "小区数据",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/newmb',
    component: () => import('@/views/villages/newmb.vue'),
    meta: {
        title: "小区数据",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/villpz',
    component: () => import('@/views/villages/villpz.vue'),
    meta: {
        title: "小区配置",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/muser',
    component: () => import('@/views/villages/muser.vue'),
    meta: {
        title: "小区免费",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/order',
    component: () => import('@/views/order/order.vue'),
    meta: {
        title: "订单首页",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/ordercd',
    component: () => import('@/views/order/charge.vue'),
    meta: {
        title: "订单首页",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/device',
    component: () => import('@/views/device/device.vue'),
    meta: {
        title: "设备首页",
        hidden: true,
        keeps: true,
        flag: false,
        data: null,
    },
},{
    path: '/leave',
    component: () => import('@/views/leave/leave.vue'),
    meta: {
        title: "离线设备",
        hidden: true,
        keeps: true,
        flag: false,
    },
},{
    path: '/Gxq',
    component: () => import('@/views/device/xq.vue'),
    meta: {
        title: "设备详情",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/zdcs',
    component: () => import('@/views/device/zd.vue'),
    meta: {
        title: "设备管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/css',
    component: () => import('@/views/device/css.vue'),
    meta: {
        title: "设备管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/set',
    component: () => import('@/views/device/set.vue'),
    meta: {
        title: "设备管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/wgcs',
    component: () => import('@/views/device/wgcs.vue'),
    meta: {
        title: "设备管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/kxq',
    component: () => import('@/views/device/kxq.vue'),
    meta: {
        title: "设备管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/channel',
    component: () => import('@/views/device/channel.vue'),
    meta: {
        title: "信道修改",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/user',
    component: () => import('@/views/user/user.vue'),
    meta: {
        title: "用户管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/into',
    component: () => import('@/views/into/into.vue'),
    meta: {
        title: "分成管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/intos',
    component: () => import('@/views/intos/into.vue'),
    meta: {
        title: "提现管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/users',
    component: () => import('@/views/user/userlists.vue'),
    meta: {
        title: "用户首页",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/userlists1',
    component: () => import('@/views/user/userlists1.vue'),
    meta: {
        title: "用户详情",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/ulists2',
    component: () => import('@/views/user/ulists2.vue'),
    meta: {
        title: "魔豆转让",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/ulists3',
    component: () => import('@/views/user/ulists3.vue'),
    meta: {
        title: "账户清零",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/elecard',
    component: () => import('@/views/elecard/elecard.vue'),
    meta: {
        title: "电卡管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/elecard1',
    component: () => import('@/views/elecard/xz.vue'),
    meta: {
        title: "电卡新增",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/elecard2',
    component: () => import('@/views/elecard/xg.vue'),
    meta: {
        title: "电卡修改",
        hidden: false,
        keeps: false,
        flag: false
    }
},{
    path: '/recom',
    component: () => import('@/views/recom/recom.vue'),
    meta: {
        title: "推荐商家",
        hidden: true,
        keeps: false,
        flag: false
    }
},
{
    path: '/recoms',
    component: () => import('@/views/recom/recoms.vue'),
    meta: {
        title: "推荐商家提交",
        hidden: true,
        keeps: false,
        flag: false
    }
},
{
    path: '/operate',
    component: () => import('@/views/operate/operate.vue'),
    meta: {
        title: "运营管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/main',
    component: () => import('@/views/main/main.vue'),
    meta: {
        title: "维修人员",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/traffic',
    component: () => import('@/views/traffic/traffic.vue'),
    meta: {
        title: "流量卡管理",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/gain',
    component: () => import('@/views/gain/gain.vue'),
    meta: {
        title: "增值收入",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/ter',
    component: () => import('@/views/terminal/ter2.vue'),
    meta: {
        title: "终端修改",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/terminal',
    component: () => import('@/views/terminal/terminal.vue'),
    meta: {
        title: "终端查询",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/ref',
    component: () => import('@/views/refund/ref.vue'),
    meta: {
        title: "线上退款",
        hidden: true,
        keeps: false,
        flag: false
    }
},{
    path: '/wen',
    component: () => import('@/views/market/wen.vue'),
    meta: {
        title: "问题反馈",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/ser',
    component: () => import('@/views/market/ser.vue'),
    meta: {
        title: "客服托管",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/charg',
    component: () => import('@/views/market/charg.vue'),
    meta: {
        title: "安心充",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/play',
    component: () => import('@/views/play/play.vue'),
    meta: {
        title: "场地险",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/tb',
    component: () => import('@/views/play/playtb.vue'),
    meta: {
        title: "场地险投保",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/topay',
    component: () => import('@/views/play/topay.vue'),
    meta: {
        title: "即将到期",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/record',
    component: () => import('@/views/play/record.vue'),
    meta: {
        title: "购买记录",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/use',
    component: () => import('@/views/play/use.vue'),
    meta: {
        title: "使用说明",
        keeps: false,
        hidden: true,
        flag: false
    }
},{
    path: '/alarm',
    component: () => import('@/views/alarm/alarm.vue'),
    meta: {
        title: "异常报警",
        hidden: true,
        keeps: false,
        flag: false
    }
}]
let router = new VueRouter({
    routes,
    mode: "hash",
})
export default router