<template>
    <div class='more'>
        <div v-for="item in listData" :key="item.notice_id" class="more_list" @click="godata(item.notice_id)">
            <div class="list_div1">{{ item.notice_title }}</div>
            <div class="list_div2">{{ item.ctime }}</div>
            <div class="list_div3" v-if="item.is_read == 0"></div>
        </div>
    </div>
</template>

<script>
import { getSystz } from "@/api/sys"
export default {
    components: {},
    data() {
        return {
            listData: [],
            daTa: null,
        };
    },
    methods: {
        async godata(id) {
            this.$router.push({
                path: '/morelist',
                query: { id: id }
            })
        },
        async get() {
            let data = await getSystz({ token: localStorage.getItem('token') });
            if (data.data.code == 1) {
                this.listData = data.data.data;
            }
        },
    },
    created() {
        this.get();
    },
    beforeRouteEnter(to, from, next) {
        if (from.path == '/morelist') {
            next(vm => {
                vm.get();
            });
        } else {
            next();
        }
    }
}
</script>
<style lang='less'>
.more {
    padding: 1rem;

    .more_list {
        background-color: #fff;
        border-radius: .6rem;
        margin-bottom: .6rem;
        padding: .6rem 1rem;
        line-height: 1.6;
        position: relative;

        .list_div1 {
            font-size: 1.7rem;
        }

        .list_div2 {
            font-size: 1.5rem;
            color: #999;
        }

        .list_div3 {
            background-color: red;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 2rem;
        }
    }
}
</style>