<template>
  <div class="charts">
    <div v-if="sure" class="fl1 loads">
        <div class="xql"><van-loading size="26px" color="#fff" type="spinner" vertical>加载中...</van-loading></div>
    </div>
    <div class="cock_bt" ref="myEchart2" id="main2"></div>
    <div class="cock_bt" ref="myEchart" id="main"></div>
    <div class="cock_bt" ref="myEchart3" id="main3"></div>
    <div class="cock_bt" ref="myEchart1" id="main1"></div>
    <div style="height: 6rem;"></div>
  </div>
</template>

<script>
import { getSct } from '@/api/sys'
export default {
  components: {},
  data() {
    return {
        myEchart: null,
        myEchart1: null,
        myEchart2: null,
        myEchart3: null,
        list1x:[],
        sure:false,
        list1Data:[],
        list2Data:[],
        list3Data:[],
        list4Data:[],
        list2x:[],
    };
  },
  methods: {
    async get(){
        this.sure = true;
        let data = await getSct();
        this.list1x = data.data.data.line1_categories;
        this.list1Data = data.data.data.line1_data;
        this.list2x = data.data.data.line2_categories;
        this.list2Data = data.data.data.line2_data;
        this.list3Data = data.data.data.line3_data;
        this.list4Data = data.data.data.line4_data;
        this.myEchart = await this.$echarts.init(this.$refs.myEchart);
        this.myEchart1 = await this.$echarts.init(this.$refs.myEchart1);
        this.myEchart2 = await this.$echarts.init(this.$refs.myEchart2);
        this.myEchart3 = await this.$echarts.init(this.$refs.myEchart3);
        this.sure = false;
        let set = {
            title: [{
                text: '近一年月收入',
                textStyle: {
                    fontSize: 16,
                    fontWeight: "normal",
                    color: "#333"
                },
                left: "center",
                top: "7%",
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: "none",
                }
            },
            grid: {
                top: "26%",
                bottom: '5%',
                containLabel: true,
            },
            xAxis: [{
                type: "category",
                axisTick: {
                    show: true
                },
                boundaryGap: false,
                data: this.list1x,
            }],
            yAxis: [{
                type: "value",
                axisTick: {
                    show: false,
                    lineStyle: {color: '#FFF', }
                },
                axisLabel: { show :false},
            }],
            series: [{
                name: '钱包充值',
                type: 'line',
                stack: 'Total',
                data: this.list1Data[0].data,
                // symbol: 'none', //中间点
                smooth: true, // 圆弧
            },{
                name: '月卡充值',
                type: 'line',
                data: this.list1Data[2].data,
                smooth: true,
            },{
                name: '单次充电',
                type: 'line',
                data: this.list1Data[1].data,
                smooth: true,
            }],
            color: ['#f05016', '#5aad42','#1abbfe']
        };
        let set1 = {
            title: [{
                text: '近一个年充电次数',
                textStyle: {
                    fontSize: 16,
                    fontWeight: "normal",
                    color: "#333"
                },
                left: "center",
                top: "7%",
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: "none"
                }
            },
            grid: {
                top: "26%",
                bottom: '5%',
                containLabel: true,
            },
            xAxis: [{
                type: "category",
                axisTick: {
                    show: true
                },
                boundaryGap: false,
                data: this.list2x,
            }],
            yAxis: [{
                type: "value",
                axisTick: {
                    show: false,
                    lineStyle: {color: '#FFF', }
                },
                axisLabel: { show :false},
            }],
            series: [{
                name: '钱包充电',
                type: 'line',
                stack: 'Total',
                data: this.list2Data[0].data,
                smooth: true,
            },{
                name: '电卡充电',
                type: 'line',
                data: this.list2Data[2].data,
                smooth: true,
            },{
                name: '单次充电',
                type: 'line',
                data: this.list2Data[1].data,
                smooth: true,
            }],
            color: ['#f05016', '#5aad42','#1abbfe']
        };
        let set2 = {
            title: [{
                text: '近一个月收入',
                textStyle: {
                    fontSize: 16,
                    fontWeight: "normal",
                    color: "#333"
                },
                left: "center",
                top: "7%",
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: "none"
                }
            },
            grid: {
                top: "26%",
                bottom: '5%',
                containLabel: true,
            },
            xAxis: [{
                type: "category",
                axisTick: {
                    show: true
                },
                axisLabel: { interval: 7 },
                boundaryGap: false,
            }],
            yAxis: [{
                type: "value",
                axisTick: {
                    show: false,
                    lineStyle: {color: '#FFF', }
                },
                axisLabel: { show :false},
            }],
            series: [{
                name: '收入金额',
                type: 'line',
                stack: 'Total',
                data: this.list3Data,
                // symbol: 'none', //中间点
                smooth: true, // 圆
            }],
            color: ['#5aad42']
        };
        let set3 = {
            title: [{
                text: '近一个月充电次数',
                textStyle: {
                    fontSize: 16,
                    fontWeight: "normal",
                    color: "#333"
                },
                left: "center",
                top: "7%",
            }],
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: "none"
                }
            },
            grid: {
                top: "26%",
                bottom: '5%',
                containLabel: true,
            },
            xAxis: [{
                type: "category",
                axisTick: {
                    show: true
                },
                axisLabel: { interval: 7 },
                boundaryGap: false,
            }],
            yAxis: [{
                type: "value",
                axisTick: {
                    show: false,
                    lineStyle: {color: '#FFF'}
                },
                axisLabel: { show :false},
            }],
            series: [{
                name: '充电次数',
                type: 'line',
                stack: 'Total',
                data: this.list4Data,
                // symbol: 'none', //中间点
                smooth: true, // 圆弧
            }],
            color: ['#1abbfe']
        };
        this.myEchart.setOption(set);
        this.myEchart1.setOption(set1);
        this.myEchart2.setOption(set2);
        this.myEchart3.setOption(set3);
        window.onresize = this.myEchart.resize;
        window.onresize = this.myEchart1.resize;
        window.onresize = this.myEchart2.resize;
        window.onresize = this.myEchart3.resize;
    }
  },
  created() {},
  mounted() {this.get()}
};
</script>
<style lang='less'>
.charts{
    padding: 0 1.4rem;
    .cock_bt {
        height: 26rem;
        display: flex;
        margin-top: 1rem;
        background-color: #fff;
        border-radius: .6rem;
        align-items: center;
        font-size: 1.5rem;
    }
}
</style>