<template>
  <div class="search fl1" @click.self="qx()">
    <div class="sea_box">
      <div v-if="sure" class="fl1 loads">
            <div class="xql"><van-loading size="26px" color="#fff" type="spinner" vertical>加载中...</van-loading></div>
        </div>
      <div class="sea_inp fl1">
        <div class="searchInput">
          <input type="hidden" id="search_type" :value="data" ref="type"/>
          <input
            type="text"
            id="search_value"
            v-model="vals"
            @input="zero()"
            placeholder="请输入查询条件"
          />
        </div> &nbsp;
        <img src="../assets/cha.png" style="width: 1.5rem;height: 1.5rem;" @click="qx()">&nbsp;
      </div>
      <ul class="search_ul" v-if="data == 'vender'">
        <label class="weui-cells_checkbox weui-check__label" v-for="(item,index) in list" :key="index" @click="sub(item)">
            <li class="fl1 sea_li">
            <div class="items-name">{{ item.vender_name }}</div>
            <input
              type="checkbox"
              :data-vender_id="item.vender_id"
              :data-vender_name="item.vender_name"
            />
            <van-icon name="circle" /></li>
            <div class="line"></div>
        </label>
      </ul>
      <ul class="search_ul" v-if="data == 'district'">
        <label class="weui-cells_checkbox weui-check__label" v-for="(item,index) in list" :key="index" @click="sub(item)">
            <li class="fl1 sea_li">
            <div class="items-name">{{ item.district_name }}</div>
            <input
              type="checkbox"
              :data-district_id="item.district_id"
              :data-district_name="item.district_name"
            />
            <van-icon name="circle" /></li>
            <div class="line"></div>
        </label>
      </ul>
      <ul class="search_ul" v-if="data == 'area'">
        <label class="weui-cells_checkbox weui-check__label" v-for="(item,index) in list" :key="index" @click="sub(item)">
            <li class="fl1 sea_li">
            <div class="items-name">{{ item.area_name }}</div>
            <input
              type="checkbox"
              :data-district_id="item.district_id"
              :data-district_name="item.district_name"
            />
            <van-icon name="circle" /></li>
            <div class="line"></div>
        </label>
      </ul>
    </div>
  </div>
</template>

<script>
import bus from "../store/bus"
import { getSys, getSysvill, getSysy } from "@/api/sys"
export default {
  components: {},
  data() {
    return {
        list:[],
        vals:'',
        sure: true,
    };
  },
  props:{
    data: {
      default: () => []
    },
    venId: {
      default: () => []
    },
    disId: {
      default: () => []
    },
    type: {
      default: () => []
    }
  },
  watch: {
    vals: function () {
      this.list = [];
      this.gs();
    }
  },
  methods: {
    zero(){
      if (this.vals == '') {  
        this.list = [];
        this.gs();
      }
    },
    async get(){
        let data = await getSys({token:localStorage.getItem('token'),vender_name:this.vals});
        this.sure = false;
        this.list = this.list.concat(data.data.data);
    },
    async gets(){
      if (this.type == '设备') {
        this.list = [{district_id:'2',district_name:'全部'},{district_id:'1',district_name:'已分配'},{district_id:'0',district_name:'未分配'}];
      }else{
        this.list = [{district_id:'0',district_name:'全部'}];
      }
        let data = await getSysvill({token:localStorage.getItem('token'),vender_id:this.venId,district_name:this.vals});
        this.sure = false;
        this.list = this.list.concat(data.data.data);
    },
    async ges(){
        this.list = [{area_id:'',area_name:'全部'}];
        if (this.disId == '1') {
          return;
        }
        let data = await getSysy({token:localStorage.getItem('token'),district_id:this.disId,area_name:this.vals});
        this.sure = false;
        this.list = this.list.concat(data.data.data);
    },
    sub(item){
      if (this.data == 'vender') {
        bus.$emit("item", item);
      }else if (this.data == 'district') {bus.$emit("item1", item);}
      else if (this.data == 'area') {bus.$emit("item2", item);}
      this.qx();
    },
    qx(){
      let t = false;
      bus.$emit("abc", t);
    },
    gs(){ if(this.$refs.type.value=="vender"){this.get()}else if (this.data == 'district'){this.gets()}else if (this.data == 'area'){this.ges()}},
  },
  mounted() { this.gs(); },
};
</script>
<style lang='less'>
.search {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  
  .search_ul{
    overflow: auto;
    height: 40rem;
  }

  .sea_box {
    background-color: #fff;
    padding: 2rem;
    width: 80%;
    border-radius: 1rem;

    .sea_li{
      justify-content: space-between;
      line-height: 1.8;
      font-size: 1.5rem;
      padding:0 0.8rem;
    }

    .sea_inp {
      margin-bottom: 1rem;
      justify-content: space-between;

      .searchInput {
        width: 86%;
        input {
          width: calc(100% - .8rem);
          background-color: transparent;
          padding-left: 0.8rem;
          border-radius: .8rem;
          border: .1rem solid #9ecbf4;
          line-height: 3.3rem;
        }
      }
    }
    
    input[type="checkbox" i]{
        display: none;
        appearance: none;
    }
  }
}
</style>